<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header>
        <b-card-title v-if="totalUsers > 0">
          Iscritti nella tua rete: {{ totalUsers }}
        </b-card-title>
        <b-card-title v-else>
          Non ci sono iscritti nella tua rete
        </b-card-title>
        <b-card-text class="mr-25 mb-0">
          <!-- default -->
          <b-button-group
            class="my-1"
          >
            <template v-if="totalUsers > 0">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="$refs.tree.zoomIn()"
              >
                Zoom
                <feather-icon
                  icon="PlusIcon"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="$refs.tree.zoomOut()"
              >
                Zoom
                <feather-icon
                  icon="MinusIcon"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="$refs.tree.restoreScale()"
              >
                Reset
                <feather-icon
                  icon="RepeatIcon"
                />
              </b-button>
            </template>
            <b-button
              v-if="$can('store', 'Users')"
              variant="outline-primary"
              @click="openModal(null, false, null)"
            >
              Aggiungi incaricato o cliente
              <feather-icon
                icon="PlusIcon"
              />
            </b-button>
          </b-button-group>
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <vue-tree
          v-if="totalUsers > 0 && Object.keys(getTreeListIscritti()).length > 1"
          ref="tree"
          :dataset="getTreeListIscritti()"
          :config="treeConfig"
          link-style="curve"
          :collapse-enabled="false"
          style="height: 1000vh"
        >
          <template v-slot:node="{ node }">
            <div
              v-if="$can('store', 'Users') && node.adder !== undefined && node.adder === 'true'"
              class="rich-media-node"
            >
              <b-button
                v-if="($can('users', 'Users') || loggedUser && loggedUser.qualification.can_select_upline)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class="btn-icon"
                @click="openModal(node.code, true, node.position)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
              </b-button>
            </div>
            <div
              v-else
              class="rich-media-node"
            >
              <div
                style="padding: 4px 0; font-weight: bold;"
                class="text-center"
              >
                <b-img-lazy
                  :src="node.gender === 'm' || node.gender === null ? require('@/assets/images/tree/usericon_male2.svg') : require('@/assets/images/tree/usericon_female4.svg')"
                />
              </div>
              <div
                style="padding: 4px 0; font-weight: bold;"
                class="text-center"
              >
                {{ node.code }}
              </div>
              <span
                :id="node.id"
                style="padding: 4px 0; font-weight: bold; white-space: nowrap"
                @click="showTooltip(node.id)"
              >
                {{ node.fullName }}
              </span>
              <b-tooltip
                v-if="node.vpcs"
                :target="node.id"
                placement="left"
                triggers="hover"
                variant="secondary"
              >
                {{ node.type }}<br>
                Nome completo: {{ node.fullName }}<br>
                Sponsor: {{ node.sponsor ? node.sponsor.fullName : null }}<br>
                Gamba SX: {{ node.vpcs.vpc_left }}<br>
                Gamba DX: {{ node.vpcs.vpc_right }}<br>
                <template v-if="node.type === 'incaricato'">
                  Qualifica reale: {{ node.qualification.qualification.name }}<br>
                  Qualifica nominale: {{ node.qualification.qualifica_nominale }}<br>
                </template>
                Stato Autoconsumo: {{ node.subscription }}
              </b-tooltip>
            </div>
          </template>
        </vue-tree>

        <user-list-create
          v-if="$can('store', 'Users')"
          :type-options="typeOptions"
          :status-options="statusOptions"
          :gender-options="genderOptions"
          :si-no-options="siNoOptions"
          :upline-code="code"
          :manual-code="manual"
          :upline-dir="dir"
          @refetch-data="reload()"
        />

      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import {
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BButton,
  BTooltip,
  VBTooltip,
  BCardText,
  BImgLazy,
  BButtonGroup,
} from 'bootstrap-vue'
  // eslint-disable-next-line import/extensions
import Ripple from 'vue-ripple-directive'
import VueTree from '@ssthouse/vue-tree-chart'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import UserListCreate from '@/views/iscritti/iscritti/iscritti-create/UserListCreate.vue'
import { can } from '@core/libs/acl/utils'

export default {
  name: 'UsersTree',
  components: {
    UserListCreate,
    VueTree,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BButton,
    BTooltip,
    BCardText,
    BImgLazy,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      show: false,
      code: null,
      manual: true,
      dir: 'sinistra',
      treeConfig: {
        nodeWidth: 120,
        nodeHeight: 80,
        levelHeight: 200,
      },
    }
  },
  methods: {
    showTooltip(id) {
      this.$root.$emit('bv::show::tooltip', id)
    },
    hideTooltip() {
      this.$root.$emit('bv::hide::tooltip', 'tooltip-button-2')
    },
    openModal(code, manual, dir) {
      this.code = code
      this.manual = manual
      this.dir = dir === 'lft' || dir === null ? 'sinistra' : 'destra'
      this.$bvModal.show('modal-create-user')
    },
    reload() {
      window.location.reload()
    },
  },
  setup() {
    const {

      // Options
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
      //

      tree,
      fetchTreeIscritti,
      totalUsers,
      getTreeListIscritti,
      refetchData,

      refetchLoggedUser,
      loggedUser,
    } = useUserList()
    if (!can('users', 'Users')) {
      refetchLoggedUser()
    }
    tree.value = true
    fetchTreeIscritti()

    return {
      // Options
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
      //
      tree,
      totalUsers,
      getTreeListIscritti,
      refetchData,
      loggedUser,
    }
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tree-container {
        overflow: auto !important;
    }

    .tree-container .dom-container {
        transform: none !important;
    }

    .tree-container > svg {
        transform: none !important;
    }

    .rich-media-node {
        width: 120px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: black;
        /*background-color: #ffffff;*/
        border-radius: 20px;
    }
</style>
